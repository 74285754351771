import React from 'react';
import Layout from '../components/Layout.js';
import Head from '../components/head';
import pajaroHablando from "../images/pajaroHablando.jpg";

const Contacto = () => {
  return (
      <Layout>
      <Head title="Contacto"/>
      <div className="showcase-container">
        <img src={pajaroHablando} alt=""/>
      </div>
      <h2 className="section-text" style={{margin:"0 0 .8em 0",textAlign: "center", fontSize:"1.3em", fontWeight:"bold"}}>hola@bumbaeditorial.com</h2>
        <section className="wideExpandTextoSection">
        <p className="section-text">Nuestras oficinas se encuentran en la Ciudad de México. Pero como nuestros libros, estamos en todos lados. No dudes en contactarnos para cualquier proyecto, pregunta, saludo o aclaración.
        </p>
      </section>
      </Layout>
)
}

export default Contacto;
